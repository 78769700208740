import React from 'react';
import PostApiService from '../../services/post-api-services';
import FileBase64 from 'react-file-base64';
import config from '../../config';

export default class EditPost extends React.Component {
	state = {
		post: {},
		uploadedFile: null,
		post_type: 'Text'
	};

	successfulSubmission = () => {
		const { location, history } = this.props;
		const destination = (location.state || {}).from || '/';
		history.push(destination);
	};
	generateMessage(string) {
		return <p>{string}</p>;
	}
	componentDidMount() {
		const postId = this.props.match.params.post_id;
		return fetch(`${config.API_ENDPOINT}/posts/${postId}`)
			.then((res) => {
				return res.json();
			})
			.then((res) => {
				this.setState({
					post: res
				});
			});
	}
	handleSubmit = (ev) => {
		ev.preventDefault();
		ev.persist();
		const {
			post_type,
			title,
			text_title,
			text_content,
			caption,
			video,
			iframe,
			embed
		} = ev.target;
		let newPost = {
			id: this.props.match.params.post_id,
			type: this.state.post_type,
			title: title.value
		};

		switch (post_type.value) {
			case 'Text':
				newPost.text_title = text_title.value;
				newPost.text_content = text_content.value;
				break;
			case 'Image':
				newPost.image = this.state.uploadedFile.base64;
				newPost.caption = caption.value;
				break;
			case 'Video':
				newPost.video = video.value;
				newPost.caption = caption.value;
				break;
			case 'Iframe':
				newPost.iframe = iframe.value;
				break;
			case 'Embed':
				newPost.embed = embed.value;
				break;
			default:
		}
		PostApiService.updatePost(newPost).then((res) => {
			if (!res.ok) {
				this.generateMessage(res.error);
			}
			this.generateMessage('Your post was successfully updated');

			switch (this.state.post_type) {
				case 'Text':
					title.value = '';
					text_title.value = '';
					text_content.value = '';
					break;
				case 'Image':
					title.value = '';
					caption.value = '';
					break;
				case 'Video':
					title.value = '';
					video.value = '';
					break;
				case 'Iframe':
					iframe.value = '';
					break;
				case 'Embed':
					embed.value = '';
					break;
				default:
			}
			setTimeout(() => {
				this.successfulSubmission();
			}, 500);
		});
	};

	updatePostType = (e) => {
		this.setState({
			post_type: e.target.value
		});
	};
	getFile(file) {
		this.setState({ uploadedFile: file });
	}

	renderFieldTypes() {
		let fields = '';
		switch (this.state.post_type) {
			case 'Image':
				fields = (
					<>
						<label htmlFor='image'>Insert Image Address</label>

						<FileBase64
							id='image'
							name='image'
							accept='image/png, image/jpeg'
							multiple={false}
							onDone={this.getFile.bind(this)}
						/>
						<label htmlFor='caption'>Image Caption</label>
						<textarea
							type='text'
							id='caption'
							name='caption'
							placeholder={this.state.post.caption}
							aria-label='Image Caption'
						></textarea>
					</>
				);
				break;
			case 'Text':
				fields = (
					<>
						<label htmlFor='text_title'>Headline</label>
						<input
							type='text'
							id='text_title'
							name='text_title'
							placeholder='Headline'
							aria-label='Text Headline'
							defaultValue={this.state.post.text_title}
						></input>
						<label htmlFor='text_content'>Body</label>
						<textarea
							type='text'
							id='text_content'
							name='text_content'
							aria-label='Text Body'
							placeholder={this.state.post.text_content}
						></textarea>
					</>
				);
				break;
			case 'Video':
				fields = (
					<>
						<label htmlFor='video'>Enter a Vimeo ID below</label>
						<input
							type='text'
							id='video'
							name='video'
							aria-label='Vimeo ID'
							defaultValue={this.state.post.video}
						></input>
						<label htmlFor='caption'>Video Caption</label>
						<textarea
							type='text'
							id='caption'
							name='caption'
							aria-label='Video Caption'
							placeholder={this.state.post.caption}
						></textarea>
					</>
				);
				break;
			case 'Iframe':
				fields = (
					<>
						<label htmlFor='iframe'>Enter URL to Embed</label>
						<input
							type='text'
							id='iframe'
							name='iframe'
							placeholder='Enter secure URL (https)'
						></input>
					</>
				);
				break;
			case 'Embed':
				fields = (
					<>
						<label htmlFor='embed'>Enter URL to Embed</label>
						<input
							type='text'
							id='embed'
							name='embed'
							placeholder='Embed your code here'
						></input>
					</>
				);
				break;
			default:
		}
		return <div className='add-post-fields'>{fields}</div>;
	}

	render() {
		return (
			<form className='AddPostForm' onSubmit={this.handleSubmit}>
				<div className='form-title'>
					<h2>Edit Post</h2>
				</div>
				<div className='message'>{this.generateMessage()}</div>
				<div className='select'>
					<label htmlFor='post_type'>Post Type</label>
					<select
						required
						aria-label='Select your post type'
						name='post_type'
						id='post_type'
						onChange={this.updatePostType}
						defaultValue={this.props.post_type}
					>
						<option value='Text'>Text</option>
						<option value='Image'>Image</option>
						<option value='Video'>Video</option>
						<option value='Iframe'>Embed Iframe (URL)</option>
						<option value='Embed'>Embed Iframe (Code)</option>
					</select>
				</div>
				<div className='form-fields'>
					<label htmlFor='title'>Post Title</label>
					<input
						type='text'
						name='title'
						id='title'
						placeholder='Post Title'
						defaultValue={this.state.post.title}
					></input>
					{this.renderFieldTypes()}
				</div>
				<button type='submit' className='form-submit' aria-label='Submit Form'>
					Submit
				</button>
			</form>
		);
	}
}
